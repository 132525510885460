<div *ngIf="(user | async) || {} as user">
  <mat-toolbar color="primary">
    <mat-toolbar-row class="toolbar">
        <img src="../assets/images/bento.png" class="title">
        <span style="float: left">赤坂のお弁当</span>

        <span style="position: absolute; right : 10px;">
          <img src="{{user.photoURL}}" class="photo">
          <span id="username">{{user.displayName}}</span>
        </span>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
<br/>
<router-outlet></router-outlet>

<br/>
<app-signin (event)="onReceiveEventFromSignIn($event)"></app-signin>
<br/>

<div style="text-align:center;">
  <img src="../assets/images/url_qr.png">
</div>

