import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, } from '@angular/fire/firestore'; // 追加

@Component({
  selector: 'app-shop-list',
  templateUrl: './shop-list.component.html',
  styleUrls: ['./shop-list.component.scss']
})
export class ShopListComponent implements OnInit {

  constructor(
     private database: AngularFirestore
    ,private storage : AngularFireStorage
  ) { }

  shops: Shop[];
  shopsDb : AngularFirestoreCollection<Shop>;
  ngOnInit() {
    this.database.collection<Shop>('shops').valueChanges().subscribe((shops) => {
      this.shops = [];
      shops.forEach(shop => {
        this.storage.ref(shop.logo).getDownloadURL().subscribe((url) => {
          shop.logoUrl = url;
        });
        this.shops.push(shop);
      });
    });
  }
}

export interface Shop {
  uid      : string;
  nameJpn  : string;
  nameRmn  : string;
  url      : string;
  logo     : string;
  logoUrl  : string;
  public   : boolean;
}
