
<div *ngFor="let shop of shops" class="shop-area">
  <div class="shop-logo-area">
    <img src="{{shop.logoUrl}}" class="shop-logo">
  </div>
  <div class="shop-name-area">
    <h2 class="shop-name">{{shop.name}}</h2>
    
    <p class="shop-remaining-none" *ngIf="shop.remaining=='none'">売り切れ</p>
    <p class="shop-remaining-few" *ngIf="shop.remaining=='few'">残り僅か</p>
    <p class="shop-remaining-onsale" *ngIf="shop.remaining=='onsale'">販売中</p>
    <p class="shop-remaining-hide" *ngIf="shop.remaining=='hide'">非公開</p>
  </div>

  
  
</div>
