import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShopListComponent } from './customer/shop-list/shop-list.component';
import { ItemListComponent } from './customer/item-list/item-list.component';
import { ItemDetailComponent } from './customer/item-detail/item-detail.component';
import { MainMenuComponent } from './owner/main-menu/main-menu.component';
import { ItemManagementComponent } from './owner/item-management/item-management.component';
import { ShopOpenComponent } from './owner/shop-open/shop-open.component';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { SigninComponent } from './signin/signin.component';

import { environment } from '../environments/environment';
import { MatToolbarModule } from '@angular/material';
import { ShopInfoComponent } from './customer/shop-info/shop-info.component';

@NgModule({
  declarations: [
    AppComponent,
    ShopListComponent,
    ItemListComponent,
    ItemDetailComponent,
    MainMenuComponent,
    ItemManagementComponent,
    ShopOpenComponent,
    UserManagementComponent,
    SigninComponent,
    ShopInfoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig), // angularfireの設定
    AngularFireAuthModule, // angularfireのAuth用モジュール
    MatToolbarModule,
  ],
  providers: [AngularFirestore],
  bootstrap: [AppComponent]
})
export class AppModule { }
