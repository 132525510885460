import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  user: Observable<firebase.User>;

  constructor(
     private angularFireAuth: AngularFireAuth
  ) { }

  @Output() event = new EventEmitter<Observable<firebase.User>>();

  ngOnInit() {
    this.user = this.angularFireAuth.authState;
    this.user.subscribe(u => {
       this.event.emit(this.user);
    })
  }

  // ログアウト
  async logout() {
    this.angularFireAuth.signOut();
  }

  async loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    const credential = await this.angularFireAuth.signInWithPopup(provider);
  }

  async loginWithFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider();
    const credential = await this.angularFireAuth.signInWithRedirect(provider);
  }

  async loginWithTwitter() {
    const provider = new firebase.auth.TwitterAuthProvider();
    const credential = await this.angularFireAuth.signInWithRedirect(provider);
  }


}