import { Component } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'akasaka-lunchbox';
  user: Observable<firebase.User>;

  onReceiveEventFromSignIn(user : Observable<firebase.User>) {
    this.user = user;
  }
}

