<div *ngIf="(user | async) || {} as user" class="account-area">


  <div *ngIf="!user.uid;">
    <!-- 認証されていない場合 -->

    <button (click)="loginWithFacebook()" class="facebook-btn">
      <i class="fab fa-facebook fa-1x"></i>
      Facebookでログインする
    </button>
    <br/>

    <button (click)="loginWithTwitter()" class="twitter-btn">
      <i class="fab fa-twitter fa-1x"></i>
      Twitterでログインする
    </button>
    <br/>

    <button (click)="loginWithGoogle()" class="google-btn">
      <i class="fab fa-google fa-1x"></i>
      Googleでログインする
    </button>
    <br/>
  </div>
  this is a test2!
  <div *ngIf="user.uid">
    <!-- 認証されている場合 -->
    <button (click)="logout()" class="logout-btn">
      <i class="fas fa-sign-out-alt fa-1x"></i>
      ログアウト
    </button>
  </div>
</div>